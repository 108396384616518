import { useParams } from 'react-router-dom';
import CardId from "../../components/Card/cardId";
import { useGetWordByIdQuery } from '../../services/data';

const SingleWord = () => {
    const {id} = useParams();
    const { data, error, isLoading } = useGetWordByIdQuery(id)
    if(data) {
        return (
            <div>
                <CardId 
                    img = {data.img}
                    word = {data.word}
                    meaning = {data.eng}
                />
            </div>
        )
    }
    else {
        return "loading..."
    }
}
export default SingleWord