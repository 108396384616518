import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { update } from "react-spring";


const initialState = {
  loading: false,
  error: null,
  success: false,
  myLikes: null,
  myDislikes: null,
};
const likesSlice = createSlice({
  name: 'likes',
  initialState,
  reducers: {
    getAllMyLikes(state, action) {
      state.myLikes = action.payload;
    },
    updateLike(state, action) {
      state.myLikes.push(action.payload);
    },
    // getMyDislikes(state, action) {
    //   state.myDislikes = action.payload;
    // },
  },
});

export const {
} = likesSlice.actions;

export default likesSlice.reducer;


