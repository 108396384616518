import moment from 'moment';
import i18n from 'i18next';
import jwt_decode from "jwt-decode";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


const URL = process.env.REACT_APP_URL
const lang = i18n.language;

// the post date
export const postDate = (date) => {
  (lang === 'ru') ? moment.locale('ru') : moment.locale('en')
  const timeAgo = moment(date).fromNow(false);
  return timeAgo
}
// convert title to url
export function convertToURL(title) {
  let convertedTitle = title.toLowerCase().replace(/ /g, '_');
  return convertedTitle;
}
//decode token 
export const decodeToken = (token) => {
  try {
    let decodedData;
    try {
        const token = localStorage.getItem('token')
        const decoded = token ? jwt_decode(token) : null;
        decodedData = decoded ? decoded.data : null;
        } catch (error) {
        console.log('Ошибка при декодировании токена:', error);
        decodedData = null;
    }
    return decodedData;
  } catch (error) {
    return null;
  }
};
//get users
export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (params, thunkAPI) => {
    const response = await axios.get(`${URL}users`, {
      params,
    });
    return response.data;
  }
);

//get user
// export const getUserById = async (id) => {
//   try {
//     const response = await fetch(`${URL}users/${id}`, {
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json, text/plain, */*'
//         // 'X-RapidAPI-Key': 'your-api-key',
//         // 'X-RapidAPI-Host': 'jokes-by-api-ninjas.p.rapidapi.com'
//       }
//     });
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//     return data;
//     // console.log(data)
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

export const fetchUserData = async (id) => {
  try {
    const response = await fetch(`${URL}users/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain, */*'
        // 'X-RapidAPI-Key': 'your-api-key',
        // 'X-RapidAPI-Host': 'jokes-by-api-ninjas.p.rapidapi.com'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching user:', error);
  }
};

  // var storedUserInfo = localStorage.getItem('userInfo');
  // var userInfo = JSON.parse(storedUserInfo);