import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader } from '../_helpers/auth-header'
const URL = process.env.REACT_APP_URL

export const nalleApi = createApi({
  reducerPath: 'nalleApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query() {
        return {
          url: `users`,
          method: `POST`,
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
        }
      }
    }),
    getUserById: builder.query({
      query(id) {
        return {
          url: `users/${id}`,
          method: `GET`,
          headers: {'Content-Type': 'application/json' },
        }
      }
    }),
    logIn: builder.mutation({
      query: (data) => ({
        url: `login`, 
        method: `POST`,
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        responseHandler: (response) => {
          // console.log(response)
          if (!response.ok) {
            return response.json().then((errorData) => {
              // console.error("Error:", errorData);
              throw new Error(errorData.message);
            });
          } else {
            return response.json().then((responseData) => {
              const headers = Object.fromEntries(response.headers.entries());
              const bearer = headers.authorization;
              localStorage.setItem('token', bearer.replace('Bearer ', ''));
              
              // console.log("response:", responseData);
              // console.log("headers:", headers);
              return {response: responseData, headers: headers };
            });
          }
        }
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: `login`,
        method: `POST`,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        responseHandler: (response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              // console.error("Error:", errorData);
              throw new Error(errorData.message);
            });
          } else {
            return response.json().then((responseData) => {
              const headers = Object.fromEntries(response.headers.entries());
              const bearer = headers.authorization;
              localStorage.setItem('token', bearer.replace('Bearer ', ''));
              return {response: responseData, headers: headers };
            });
          }
        }
      })
    })


  }),
})

export const { useGetUserByIdQuery, useGetAllUsersQuery, useLogInMutation, useSignUpMutation } = nalleApi
