import './index.scss'
import { Link } from 'react-router-dom';
import BurgerMenu from '../BurgerMenu';
import Language from '../Language/Language';
import DarkMode from '../DarkMode'
import Menu from '../Menu';
import { useTranslation } from 'react-i18next';
import { LogoSVG } from '../../svgs';

const Header = () => {
    const { i18n } = useTranslation();
    return (
        <header className='header'>
                <BurgerMenu />
                <Link to={i18n.resolvedLanguage + "/"} className="header__logo"><LogoSVG /></Link>
                <Language />
                {/* <DarkMode /> */}
                <Menu/>
        </header>
    )
}
export default Header

