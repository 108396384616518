import "./index.scss"
import { useGetLessonsQuery } from "../../services/data";
// import PostsList from "../../components/Posts/postList";

const LessonsList = () => {
const { data, error, isLoading } = useGetLessonsQuery();
    return (
        <div className="lessons">
            {/* <PostsList
                data={data}
                isLoading={isLoading}
            /> */}
        </div>
    )
}
export default LessonsList