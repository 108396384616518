import React from "react";
import { motion } from "framer-motion";
import Post from "./post";
import "./index.scss";

const News = ({ data }) => {
  return (
    <motion.div
      className="news-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {data.map(({ id, user_liked, comments, author, title_en, text_en, date, likes }) => (
        <Post
          key={id}
          id={id}
          liked={user_liked}
          comments={comments}
          author={author}
          title={title_en}
          text={text_en}
          date={date}
          likes={likes}
          titleIsLink={true} // Title as link
          showFullText={false} // Show full text
        />
      ))}
    </motion.div>
  );
};

export default News;
