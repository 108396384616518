import OneOfFour from "./OneOfFour";
import FlashCards from "./FlashCards";
import { useState } from "react";
import "./index.scss";
import {
  useGetWordsCategoriesQuery,
  useGetWordsCategoryByIdQuery,
} from "../../services/data";
import Cat from "../Cat";
import ImportWords from "./ImportWords";

const Games = () => {
  const [ selectedGame, setSelectedGame ] = useState(true);
  const [ selectCategory, setSelectCategory ] = useState()
  // const { data, error, isLoading } = useGetWordsCategoryByIdQuery("verbs")
  const { data, error, isLoading } = useGetWordsCategoriesQuery();
  const selectCategoryHandle = (category) => { 
    setSelectCategory(category)
  };
  const myWordsStorage = localStorage.getItem('imported-keys') ? JSON.parse(localStorage.getItem('imported-keys')) : [];
  const [myWords, setMyWords] = useState(myWordsStorage);
  return (
    <>
      <ImportWords />
      {selectCategory === undefined ? 
        (isLoading ? ( <Cat /> ) : //select category
        <div className="categories">
          <ul className="categories__list">
            {data.map((category, id) => (
              <li key={id} onClick={() => selectCategoryHandle(category.cat_en)}  className="categories__item">
                <button className="categories__button">{category.cat_en}</button>
              </li>
            ))}
            {myWords.length > 0 && <li onClick={() => selectCategoryHandle("my-words")} className="categories__item"><button className="categories__button">My Words</button></li>}
          </ul>
          {/* {myWords.length > 0 && <div className="categories__my-words">{myWords.map((word, id) => ( <div key={id} className="categories__my-words-item">{word.word} - {word.translate}</div>))}</div>} */}
        </div> 
        ) : //select game
        <div className="games">
          <div className="control">
            <button onClick={() => setSelectedGame(true)} className={`control__button ${selectedGame ? 'active' : ''}`}>Flash Cards</button>
            <button onClick={() => setSelectedGame(false)} className={`control__button ${!selectedGame ? 'active' : ''}`}>One Of Four</button>
          </div>
          <div className="game">
          {selectedGame ? <FlashCards category={selectCategory}/> : <OneOfFour category={selectCategory}/>}
          </div>
        </div>
      }
    </>
  )
};
export default Games;