import './index.css';
import React, { Suspense } from 'react';
import Router from './pages/Router'
import Header from './components/Header';
import Footer from './components/Footer';
import { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getData } from './features/dataSlice';
import { useDispatch } from "react-redux";
import Overlay from './components/News/Overlay';

export default function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData())
  }, [])

  useEffect(() => {
    const currentLanguage = location.pathname.split('/')[1];
    document.documentElement.setAttribute("lang", currentLanguage);
    i18n.changeLanguage(currentLanguage);
  }, [location, i18n]);

  return (
    <HelmetProvider>
      <Suspense fallback={<p>Loading...</p>}>
            <Helmet>
            <title>Nalle</title>
            </Helmet>
            {location.pathname !== `/${lang}/auth` && <Header />}
            <main className='main'>
              <Router />
              <Overlay /> 
            </main>
            {location.pathname !== `/${lang}/auth` && <Footer />}
      </Suspense>
    </HelmetProvider>

  );
}