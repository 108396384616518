import './index.scss';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import useFormPersist from 'react-hook-form-persist'
// import usePost from '../../hooks/usePost';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {  Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSignUpMutation } from '../../services/nalle_api';
import { loginSuccess, setUser } from "../../features/userSlice";


const SignUp = ({goBackButton}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  // -------------counter 3 seconds--------------
  const [activeIndex, setActiveIndex] = useState(); //active number now
  const [countdownComplete, setCountdownComplete] = useState(false); //is count finished?

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex(prevIndex => prevIndex + 1);
    }, 1000);
    setTimeout(() => { //short delay before start of countdown
      setActiveIndex(0);
    }, 200);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (activeIndex === 3) {
      setCountdownComplete(true); //countdown is finished
    }
  }, [activeIndex]);

  const items = ['1', '2', '3'];
  // -------------!counter 3 seconds--------------

  const { register, formState: { errors, isValid }, clearErrors, reset, watch, setValue, handleSubmit } = useForm()
  useFormPersist("unfinishedSignUp", { watch, setValue, });

  const [signUp, {isLoading, isError, isSuccess}] = useSignUpMutation();

  const handleSignUpForm = async (formData) => { //submit form
    const { error, data, result, response } = await signUp({ type: "singUp", formData}); 
    if(error) {
      console.log("error:", error)
    }
    else {
      dispatch(loginSuccess())
      navigate(`/${lang}/profile`);
    }
  };

  return (
    <>
      {countdownComplete ? (
        <div className='signup'>
        <h4 className='signup__title'>Sign Up:</h4>
        <form onSubmit={handleSubmit(handleSignUpForm)}>
        {/* {response && <p>Data: {response.code}</p>} */}
            <input
              placeholder='e-mail:'
              type="text"
              {...register('email', {
              required: 'email is required!',
              pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
              }
              })}
              aria-invalid={errors.email ? "true" : "false"} 
            />
            <input
              type="password" name="password" 
              placeholder='password:'
                {...register('password', {
                  required: "password is required!",
                  },
                )}
            />
            <div className="signup__control">
              <button onClick={goBackButton} className='signup__button'>Back</button>
              <input type="submit" disabled={!isValid} className={isValid ? '' : 'disabled'} value='Sign Up'/>
            </div>
        </form>
        {(errors.email || errors.password) && 
          <div className="errors">
            {errors.email && <div className="errors__email">{errors.email.message}</div>}
            {errors.password && <div className="errors__password">{errors.password.message}</div>}
        </div>}
        </div>
      ) : (
        <div className='creating-account'>
          <div className="creating-account__img">
            /img/
            {/* <img src="" alt="" /> */}
          </div>
          <div className='creating-account__title'>Creating Account...</div>
          <div className="creating-account__counter">
          <p className="creating-account__subtitle">On the count of three...</p>
            {items.map((item, index) => (
              <div key={index}
              className={`creating-account__digit ${index === activeIndex ? 'open' : ''}`} >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
};
export default SignUp