import { DndContext } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import Draggable from "./draggable";
import Droppable from "./droppable";
import "./index.scss";
import Loading from "../../Loading";
import {
  useGetWordsCategoriesQuery,
  useGetWordsCategoryByIdQuery,
} from "../../../services/data";
const OneOfFour = ({ category }) => {
  const wordsWithTranslations = [
    { word: "Run", translation: "Juosta" },
    { word: "Jump", translation: "Hypätä" },
    { word: "Throw", translation: "Heittää" },
    { word: "Slide", translation: "Liukua" },
  ];
  const { data, isError, isLoading } = useGetWordsCategoryByIdQuery(category);
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <p>Oops, failed to load</p>;
  }
  return <Game initialState={data} />;

  function Game(initialState) {
    const correctAnswer = "Jump";
    const answersArray = data.map(item => item.eng); //translates
    const wordArray = data.map(item => item.word); //finnish words
    const selectRandomItem = (arr) => { //select a random draggable word
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    };
    const [answers, setAnswers] = useState(answersArray); //store translates
    const [word, setWord] = useState(selectRandomItem(wordArray)); //store finnish words
    const [usedWords, setUsedWords] = useState([]); //store used words
    const [parent, setParent] = useState(null); //????
    // console.log("dataaa: ", data)
    //is answer correct?
    const [result, setResult] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const draggableMarkup = <Draggable id={word}>{word} </Draggable>;
    useEffect(() => {
      if (result === "correct") {
        setIsVisible(false);
        // const updatedAnswers = answers.filter(answer => answer !== correctAnswer);
        // console.log(updatedAnswers)
        setTimeout(() => {
          setIsVisible(true); // Show elements after a delay
          setParent(null); // Reset parent
          setResult(null); // Reset result
        }, 500); // Adjust delay as needed
      }
    }, [result]);

    function updateAnswersAndWord() {
      // Filter out the correct answer and update answers
      setAnswers(answers.filter((answer) => !usedWords.includes(answer)));
      // Add the new word to the usedWords array
      // setUsedWords([...usedWords, newWord]);
    }
    return (
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <div className="select-one">
          <div className="select-one__img">
            <span>
              Result: <br />
              {result}
            </span>
          </div>
          <div className="select-one__info">
            <div className="select-one__answer-is"></div>
            {/* <div>item on: {parent === null ? "base (parent === null)" : parent}</div> */}
          </div>
          {isActive}
          <div className={`select-one__content ${!isVisible ? "hidden" : ""}`}>
            <div className="select-one__list">
              {answers.map((id) => (
                // We updated the Droppable component so it would accept an `id`
                // prop and pass it to `useDroppable`
                <Droppable key={id} id={id}>
                  {parent === id ? draggableMarkup : id}
                </Droppable>
              ))}
            </div>
            <div className="select-one__base">
              {parent === null ? draggableMarkup : ""}
              <div className="select-one__shade">{word}</div>
            </div>
          </div>
        </div>
      </DndContext>
    );

    function handleDragEnd(event) {
      const { over, active } = event;
      //   If the item is dropped over a container, set it as the parent
      //   otherwise reset the parent to `null`
      if (over) {
        over.id === correctAnswer
          ? setResult("correct")
          : setResult("incorrect");
        setParent(over.id);
      } else {
        setParent(null);
        setResult(null);
      }
      setParent(over ? over.id : null);
    }
    function handleDragStart(event) {
      const { active, over } = event;
    }
  }
};

export default OneOfFour;





// function Game(initialState) {
//   const correctAnswer = "Jump";
//   const wordArray = data.map(item => item.word);
//   console.log("dataaa: ", wordArray)
//   const [answers, setAnswers] = useState(["Run", "Jump", "Throw", "Slide"]);
//   const [word, setWord] = useState("Hypätä");
//   const [usedWords, setUsedWords] = useState([]);
//   const [parent, setParent] = useState(null);
//   //is answer correct?
//   const [result, setResult] = useState(null);
//   const [isVisible, setIsVisible] = useState(true);
//   const [isActive, setIsActive] = useState(false);
//   const draggableMarkup = <Draggable id={word}>{word} </Draggable>;
//   useEffect(() => {
//     if (result === "correct") {
//       setIsVisible(false);
//       // const updatedAnswers = answers.filter(answer => answer !== correctAnswer);
//       // console.log(updatedAnswers)
//       setTimeout(() => {
//         setIsVisible(true); // Show elements after a delay
//         setParent(null); // Reset parent
//         setResult(null); // Reset result
//       }, 500); // Adjust delay as needed
//     }
//   }, [result]);

//   function updateAnswersAndWord() {
//     // Filter out the correct answer and update answers
//     setAnswers(answers.filter((answer) => !usedWords.includes(answer)));
//     // Add the new word to the usedWords array
//     // setUsedWords([...usedWords, newWord]);
//   }
//   return (
//     <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
//       <div className="select-one">
//         <div className="select-one__img">
//           <span>
//             Result: <br />
//             {result}
//           </span>
//         </div>
//         <div className="select-one__info">
//           <div className="select-one__answer-is"></div>
//           {/* <div>item on: {parent === null ? "base (parent === null)" : parent}</div> */}
//         </div>
//         {isActive}
//         <div className={`select-one__content ${!isVisible ? "hidden" : ""}`}>
//           <div className="select-one__list">
//             {answers.map((id) => (
//               // We updated the Droppable component so it would accept an `id`
//               // prop and pass it to `useDroppable`
//               <Droppable key={id} id={id}>
//                 {parent === id ? draggableMarkup : id}
//               </Droppable>
//             ))}
//           </div>
//           <div className="select-one__base">
//             {parent === null ? draggableMarkup : ""}
//             <div className="select-one__shade">{word}</div>
//           </div>
//         </div>
//       </div>
//     </DndContext>
//   );

//   function handleDragEnd(event) {
//     const { over, active } = event;
//     //   If the item is dropped over a container, set it as the parent
//     //   otherwise reset the parent to `null`
//     if (over) {
//       over.id === correctAnswer
//         ? setResult("correct")
//         : setResult("incorrect");
//       setParent(over.id);
//     } else {
//       setParent(null);
//       setResult(null);
//     }
//     setParent(over ? over.id : null);
//   }
//   function handleDragStart(event) {
//     const { active, over } = event;
//   }
// }
// };




// Modify the state to hold both the words and their translations.
// Implement logic to handle the progression through words.
// Add UI elements to display the current word and its translation.
// Implement logic to check if the user's answer is correct.
// Add a mechanism for the user to proceed to the next word.
// Here's an updated version of your code with these changes:

// javascript
// Copy code
// import { DndContext } from '@dnd-kit/core';
// import { useEffect, useState } from 'react';
// import Draggable from './draggable';
// import Droppable from './droppable';
// import "../index.scss";

// const OneOfFour = () => {
//     const wordsWithTranslations = [
//         { word: 'Run', translation: 'Juosta' },
//         { word: 'Jump', translation: 'Hypätä' },
//         { word: 'Throw', translation: 'Heittää' },
//         { word: 'Slide', translation: 'Liukua' }
//     ];

//     const [currentWordIndex, setCurrentWordIndex] = useState(0);
//     const [result, setResult] = useState(null);

//     const currentWord = wordsWithTranslations[currentWordIndex].word;
//     const currentTranslation = wordsWithTranslations[currentWordIndex].translation;

//     function handleNextWord() {
//         if (currentWordIndex < wordsWithTranslations.length - 1) {
//             setCurrentWordIndex(currentWordIndex + 1);
//             setResult(null);
//         } else {
//             // Logic to handle completion of all words
//         }
//     }

//     useEffect(() => {
//         if (result === "correct") {
//             setTimeout(() => {
//                 handleNextWord();
//             }, 500);
//         }
//     }, [result]);

//     function handleDragEnd(event) {
//         const { over } = event;
//         if (over) {
//             if (over.id === currentTranslation) {
//                 setResult("correct");
//             } else {
//                 setResult("incorrect");
//             }
//         }
//     }

//     return (
//         <DndContext onDragEnd={handleDragEnd}>
//             <div className="select-one">
//                 <div className="select-one__img">
//                     <span>{result}</span>
//                 </div>
//                 <div className="select-one__info">
//                     <div className="select-one__answer-is"></div>
//                 </div>
//                 <div className="select-one__content">
//                     <Droppable key={currentTranslation} id={currentTranslation}>
//                         <Draggable id={currentWord}>{currentWord}</Draggable>
//                     </Droppable>
//                     <div className="select-one__base">
//                         <div className="select-one__shade">{currentTranslation}</div>
//                     </div>
//                 </div>
//             </div>
//             <button onClick={handleNextWord}>Next Word</button>
//         </DndContext>
//     );
// };

// export default OneOfFour;
// In this code:

// wordsWithTranslations array holds objects with both the words and their translations.
// currentWordIndex state variable is used to keep track of the current word being displayed.
// handleNextWord function increments the currentWordIndex to move to the next word.
// handleDragEnd function checks if the dragged word matches the correct translation.
// The UI displays the current word and its translation using Draggable and Droppable components.
// A "Next Word" button allows the user to proceed to the next word.
