import React, {useCallback, memo} from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { postDate } from "../../utils";
import { useSelector, useDispatch } from 'react-redux';
import { toggleOverlay, setCurrentId, setMenuType } from '../../features/overlaySlice';

const Comment = ({ comment }) => {
  const dispatch = useDispatch();
  const showOverlay = useSelector((state) => state.overlay.showOverlay);

  const handleOverlayToggle = useCallback(() => {
    dispatch(toggleOverlay());
    dispatch(setCurrentId(comment.id));
    dispatch(setMenuType("comments"));
    document.body.classList.add('no-scroll');
  }, [dispatch, comment.id]);

  const lang = i18n.language;
  return (
    <div className="comment">
      <div className="comment__header">
        <div className="comment__info">
          <Link to={`/${lang}/u/${comment.login}`} className="comment__author">
            <img
              loading="lazy"
              src={comment.img}
              className="comment__img"
              alt="author"
            />
            <span>{comment.login},</span>
          </Link>
          <div className="comment__date"> {postDate(comment.date)}</div>
        </div>
        <div
            onClick={() => handleOverlayToggle()}
            className={`options${showOverlay ? " open" : ""}`}
        >
          <span></span>
        </div>
      </div>
      <div className="comment__text">{comment.text}</div>
    </div>
  );
};
export default memo(Comment);
