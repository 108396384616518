import { ImportSVG } from "../../svgs";
import { useState } from "react";
const ImportWords = () => {
  const [openImportMenu, setOpenImportMenu] = useState();
  const toggleImportMenu = () => {
    setOpenImportMenu(!openImportMenu);
  };

  // fetch from textarea and save to localStorage
  const [importText, setImportText] = useState('');
  const [isValid, setIsValid] = useState(false);
  const importWord = async (e) => {
    e.preventDefault();
    const keys = importText.split('\n').map(key => key.trim());
    const words = keys.map((key, index) => {
      const id = index + 1;
      const [word, eng] = key.split(', ');
      return { id, word, eng };
    });
    localStorage.setItem('imported-keys', JSON.stringify(words));
    setImportText('');
    setOpenImportMenu(false);
  }
  const handleTextChange = (e) => {
    setImportText(e.target.value);
    setIsValid(e.target.value.trim().length > 0);
  };
  // !end fetch from textarea and save to localStorage
  return (
    <div className="import-words">
      <div onClick={() => toggleImportMenu()} className="import-words__button">
        Import your words
        <div className="import-words__img"><ImportSVG /></div>
      </div>
      
      <div className={`import-words__menu ${openImportMenu ? "open" : ""}`}>
        <div className="import-words__item">Import from file</div>
        <div className="import-words__item">Import from Google Sheets</div>
        <div className="import-words__item">Import from Anki</div>
        <div className="import-words__item">Import from Quizlet</div>
        <div className="import-words__item">Import from Memrise</div>
        <form onSubmit={importWord}className="import-words__form">
          <textarea onChange={handleTextChange} className="import-words__textarea" value={importText} placeholder="word, translate"></textarea>
        <button type="submit">Send</button>
        </form>
      </div>
    </div>
  )
}
export default ImportWords;