import React, { useRef, useEffect, useState, useCallback } from 'react';
import { motion, useMotionValue, useTransform, useSpring } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { toggleOverlay, setOverlay, setCurrentId } from '../../features/overlaySlice';
import { useSelector, useDispatch } from 'react-redux';

const Overlay = ({ }) => {
  const dispatch = useDispatch();
  const showOverlay = useSelector((state) => state.overlay.showOverlay);
  const currentId = useSelector((state) => state.overlay.currentId);
  const menuItemsPosts = useSelector((state) => state.overlay.menuItemsPosts);
  const menuItemsComments = useSelector((state) => state.overlay.menuItemsComments);
  const menuType = useSelector((state) => state.overlay.menuType);
  const menuItems = menuType === "news" ? menuItemsPosts : menuItemsComments;

  const overlayRef = useRef(null);
  const containerRef = useRef(null);
  const initialTouchY = useRef(null);
  const y = useMotionValue(0);
  const opacity = useTransform(y, [0, 100], [1, 0]);
  const springConfig = { damping: 20, stiffness: 300 };
  const animatedY = useSpring(y, springConfig);

  useEffect(() => {
    const closeMenu = (event) => {
      if (event.target === overlayRef.current) {
        document.body.removeAttribute('class');
        dispatch(setOverlay(false));
      }
    };
    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, [dispatch, showOverlay]);


  const handleTouchStart = (event) => {
    initialTouchY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const touchY = event.touches[0].clientY;
    const offsetY = touchY - initialTouchY.current;
    if (offsetY > 100) {
      dispatch(setOverlay(false));
      document.body.removeAttribute('class');
      y.set(0);
    } else  {
      y.set(offsetY < 0 ? 0 : offsetY);
    }
  };
  const handleTouchEnd = () => {
    const currentY = y.get();
    if (currentY > 0 && currentY < 200) {
      y.set(0);
    }
  };
  if (!showOverlay) return null;
  return (
    <div ref={overlayRef} className={`overlay`}>
      <motion.div
        className='overlay__options'
        ref={containerRef}
        style={{
          y: animatedY,
          opacity,
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          height: menuItems.length * 50 + 50,
          zIndex: 14,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        initial={{ opacity: 0 }}
        animate={{ opacity: showOverlay ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <ul className="overlay__list">
          {menuItems.map((item, index) => (
            <li key={index} className="overlay__item">
              <NavLink className="overlay__link" to={item.link}>
                {item.label} {item.showId && `(post #${currentId})`}
              </NavLink>
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Overlay;