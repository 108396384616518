import './index.scss'
import React, { useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectNav, unblurCard } from "../../features/navSlice";
const CardId = ({img, word, meaning, sentence, translate}) => {
    const [spoiler, setSpoiler] = useState(false);
    const handleSpoiler = () => {
        setSpoiler(!spoiler)
        console.log(true)
    }
    const nav = useSelector(selectNav) 
    return (
        <div className="card">
            <div className="card__header">
                <div className={`card__img ${nav.blur ? "" : "active"}`}>
                    <img src={img}></img>
                </div>
                <div className="card__word">{word}</div>
            </div>
            <div className={`card__body ${nav.blur ? "" : "active"}`}>
                <div className="card__meaning">{meaning}</div>
                <div className="card__sentence"><span>Sentence: </span>{sentence}</div>
                <div className="card__translate"><span>Translate: </span>{translate}</div>
            </div>
            <a className="card__more-btn" onClick={handleSpoiler}>
                <svg viewBox="0 0 448 512" fill="none"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
            </a>
        </div>
    )
}
export default CardId