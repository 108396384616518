import "./index.scss"
import { useState } from 'react';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Search = ({ placeholder, data }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const lang = i18n.language;
    const { t } = useTranslation();
    const handleFilter = (event) => {
      const searchWord = event.target.value.toLowerCase()
      setWordEntered(searchWord);
      const newFilter = data.filter((value) => {
          return value.word?.toLowerCase().includes(searchWord) ||
          value.eng?.toLowerCase().includes(searchWord) ||
          value.ru?.toLowerCase().includes(searchWord);
      });
  
      if (searchWord === "") {
        setFilteredData([]);
      } else {
        setFilteredData(newFilter);
      }
    };
  
    const clearInput = () => {
      setFilteredData([]);
      setWordEntered("");
    };

    return (
        <div className="search">
        {filteredData.length != 0 && (
          <div className="dataResult">
            {filteredData.slice(0, 15).map((value, key) => {
              return (
                <NavLink key={value.id} className="dataItem" onClick={clearInput} to={lang + '/words/' + value.word}>
                  <p>{value.word}
                      <span>(</span>
                      {lang === 'en' ? (
                          value.eng
                      ) : (
                          value.ru
                      )}
                      <span>)</span>
                  </p>
                
                </NavLink>
              );
            })}
          </div>
        )}
        <div className="searchInputs">
          <input
            type="text"
            placeholder={t('search-placeholder')}
            value={wordEntered}
            onChange={handleFilter}
          />
          <div className="searchIcon">
            {filteredData.length === 0 ? (
              <span className='icons'>
                <svg viewBox="0 0 512 512" fill="currentColor"><path d="M416 208c0 46-15 88-40 123l127 126a32 32 0 0 1-46 46L331 376a208 208 0 1 1 85-168zM208 352a144 144 0 1 0 0-288 144 144 0 0 0 0 288z"/></svg>
              </span>
            ) : (
                <span className='iconc' id="clearBtn" onClick={clearInput}>
                <svg viewBox="0 0 320 512" fill="currentColor"><path d="M311 151a32 32 0 0 0-46-46L160 211 55 105a32 32 0 0 0-46 46l106 105L9 361a32 32 0 0 0 46 46l105-106 105 106a32 32 0 0 0 46-46L205 256l106-105z"/></svg>
                </span>
            )}
          </div>
        </div>
      </div>
    )
}
export default Search
