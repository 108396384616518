import { configureStore } from "@reduxjs/toolkit";
import navReducer from '../features/navSlice';
import dataSlice from "../features/dataSlice";
import lessonsSlice from "../features/lessonsSlice";
import userSlice from "../features/userSlice";
import postsSlice from "../features/postsSlice";
import likesSlice from "../features/likesSlice";
import overlaySlice from "../features/overlaySlice";
import { nalleApi } from "../services/nalle_api";
import { data } from "../services/data";
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
    reducer: {
        [nalleApi.reducerPath]: nalleApi.reducer,
        [data.reducerPath]: data.reducer,
        nav: navReducer,
        dataList: dataSlice,
        lessonsList: lessonsSlice,
        postsList: postsSlice,
        user: userSlice,
        likes: likesSlice,
        overlay: overlaySlice,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false,
    }).concat([
        nalleApi.middleware,
        data.middleware,
    ]),
})
setupListeners(store.dispatch)