import { is } from "@react-spring/shared";
import { createSlice } from "@reduxjs/toolkit";

const savedIsBlurOn = localStorage.getItem("isBlurOn!");
const initialState = {
    isOpen: false,
    blur: false,
    isBlurOn: savedIsBlurOn ? JSON.parse(savedIsBlurOn) : false,
    isOpenOptions: false,
    postId: null,
}

export const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        // open/close burger menu
        openButton: (state) => {
            state.isOpen = !state.isOpen;
            document.body.classList.add('no-scroll');
        },
        optionsButton: (state) => {
            state.isOpenOptions = !state.isOpenOptions;
            // state.isOpenOptions = !state.isOpenOptions;
            document.body.classList.add('no-scroll');
        },
        setCurrentId: (state, action) => {
            state.postId = action.payload;
        },
        // switch on/off the blur by clicking on the card
        blurButton: (state) => {
            state.blur = !state.blur;
        },
        // change value of the blur on 'true' or 'false'
        blurChange: (state, action) => {
            state.blur = action.payload;
        },
        // user setting of the blur
        userBlurSetting: (state) => {
            state.isBlurOn = !state.isBlurOn;
            localStorage.setItem("blurValue", state.isBlurOn);
            (state.isBlurOn === false) ? state.blur = false : state.blur = true
        }
    },
})

export const { openButton, blurButton, blurChange, userBlurSetting, optionsButton, setCurrentId} = navSlice.actions

export const selectNav = (state) => state.nav

export default navSlice.reducer