import './index.scss';
import { useSelector, useDispatch } from "react-redux";
import { selectNav, openButton } from "../../features/navSlice";
import React, { useEffect } from 'react';

const BurgerMenu = () => {
    const nav = useSelector(selectNav)
    const dispatch = useDispatch()
    useEffect(() => {
      {nav.isOpen ? document.body.classList.add('no-scroll') : document.body.removeAttribute('class')}
    }, [nav.isOpen]);
    return (
        <div onClick={(e) => dispatch(openButton(!nav.isOpen))} className={`header__button${nav.isOpen ? " open" : ""}`}>
            <span></span>
        </div>
    ) 
}
export default BurgerMenu
