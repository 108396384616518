import LogIn from "./LogIn";
import SignUp from "./SignUp";
import React, { useCallback } from "react";
import "./index.scss";
import { useState } from "react";
import { useNavigate, Route } from "react-router-dom";
import { LogoSVG, GoBackArrowSVG } from "../../svgs";

const Auth = () => {
  const navigate = useNavigate();
  const [showSignUp, setShowSignUp] = useState(false); // show <SingUp />. Default hidden.
  const [showLogIn, setShowLogin] = useState(false); //show <LogIn />. Default hidden.
  const handleSignUp = () => setShowSignUp(!showSignUp); 
  const handleLogIn = () => setShowLogin(!showLogIn);
  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {showLogIn ? ( // (FALSE) by default
        <LogIn goBackButton={handleLogIn} /> //change state by click back button
      ) : showSignUp ? ( // (FALSE) by default
        <SignUp goBackButton={handleSignUp} /> //change state by click back button
      ) : ( 
        <div className="auth">
          <div className="auth__img"><img src="/logo192.png" alt="" /></div>
          <div className="auth__logo"><LogoSVG /></div>
          <div className="auth__subtitle">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos vitae officiis.
          </div>
          <button onClick={handleSignUp} className="auth__signup">Create account</button>
          <button onClick={handleLogIn} className="auth__login">Log In</button>    
          <button onClick={handleGoBack} className="auth__back" ><GoBackArrowSVG />Back</button>
        </div>
      )}
    </>
  );
};
export default Auth;