import { useEffect } from "react";
import { useParams, useNavigate, NavLink, Navigate} from 'react-router-dom';
import i18n from 'i18next';
import { convertToURL, postDate } from '../../utils'
import { motion } from "framer-motion";
// import PostId from "../../components/Posts/postId";
import { useGetLessonByIdQuery } from '../../services/data';
import Loading from "../../components/Loading";
// import Comments from "../../components/Posts/comments";
import Cat from "../../components/Cat";

export default function LessonId() {
  const lang = i18n.language;
  const navigate = useNavigate();
  const {id, title} = useParams();
  const { data, isError, isLoading } = useGetLessonByIdQuery(id)
  
  useEffect(() => {
    if (data && !title) {
        const newUrl = `${convertToURL(data.title)}`;
        navigate(newUrl);
    }
  }, [id, title, lang, data, navigate]);

  return (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}>
      {/* {isLoading === true ? 
        <Cat /> :
        <PostId 
          key={data.id}
          id={data.id}
          author={data.author ? data.author : "unknown"}
          title={data.title}
          text={data.text}
          date={data.date}
          likes={data.likes}
          titleIsLink={false} //title as link
          optionsButton={false} //show or hide options button
          showFullText={true} //show full text
        />
      }  */}
      {/* <Comments /> */}
    </motion.div>
  )
}