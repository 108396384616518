import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const URL = process.env.REACT_APP_URL

export const getLessons = createAsyncThunk(
  'lessons/getLessons',
  async (params, thunkAPI) => {
    const response = await axios.get(`${URL}lessons`, {
      params,
    });
    return response.data;
  }
);

export const postLessons = createAsyncThunk(
  'lessons/postLesson',
  async (data, thunkAPI) => {
    const response = await axios.post(`${URL}lessons`, data);
    return response.data;
  }
);

const lessonsSlice = createSlice({
  name: 'lessonsList',
  initialState: {
    lessons: [],
    isLoading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLessons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lessons = action.payload;
      })
      .addCase(getLessons.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  // Add any additional actions here
} = lessonsSlice.actions;

export default lessonsSlice.reducer;




// export const getData = createAsyncThunk("dataList/getData", async () => {
//   const URL = process.env.REACT_APP_URL
//   let response = await fetch(`${URL}cards`);
//   let json = await response.json();
//   return json;
  
//   //det som returneras här, kommer att bli vår action.payload
// });

// const dataSlice = createSlice({
//   name: "dataList",
//   initialState: {
//     data: [],
//     status: null
//   },
//   extraReducers: {
//     [getData.fulfilled]: (state, action) => {
//       //If we have to totally replace the existing array:
//       state.data = action.payload;

//       //if we want to add the json to an existing array
//     //   let updateddata = state.data.concat(action.payload);
//     //   state.data = updateddata;
//       state.status = null;
//     },
//     [getData.pending]: (state) => {
//       state.status = "Fetching data. Please wait a moment...";
//     },
//     [getData.rejected]: (state) => {
//       state.status = "Failed to fetch data...";
//     }
//   }
// });

// export default dataSlice.reducer;
