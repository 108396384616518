import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { loginSuccess, logoutSuccess } from "../../features/userSlice";
import { useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setUser } from "../../features/userSlice";
import { useLogInMutation } from '../../services/nalle_api';
import { useDispatch } from "react-redux";
import { getAllMyLikes } from "../../features/likesSlice";

const LogIn = ({ goBackButton }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [ errorMessage, setErrorMessage ] = useState("")
  const [logIn, {isLoading, isError, isSuccess, error}] = useLogInMutation();
  const {
    register,
    formState: { errors, isValid },
    reset,
    unregister,
    getValues,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    } = useForm();

  useFormPersist("storageKey", { watch, setValue, unregister });

  // const { myId } = useSelector((state) => state.user);

  const handleSignInForm = async (formData) => { //submit form
    const { error, data } = await logIn({ type: "singIn", formData}); 
    if(error) {
      console.log("the error: ", error)
      // setErrorMessage(JSON.parse(error?.data).message)
    } 
    if(data.response.status === "ok") {
      // sessionStorage.removeItem("storageKey"); //remove login form input save, doesn't work 
      let user = data.response.user
      // console.log(data.response)
      dispatch(loginSuccess())
      // dispatch(setUser)
      // dispatch(getAllMyLikes(user.id))
      // dispatch(
      //   setUser({
      //     myLogin: user.login,
      //   })
      // )
      navigate(`/${lang}/profile`);
    }
  };

  //shitty code, refactor it someday...or better the whole app...just throw it right to the bin 

  useEffect(() => { //reset response error
    const clearErrorTimeout = setTimeout(() => {
        setErrorMessage("");
    }, 3000);
    return () => clearTimeout(clearErrorTimeout);
  }, [errorMessage]);

  useEffect(() => { //reset password error
    const clearErrorTimeout = setTimeout(() => {
      clearErrors("password");
    }, 3000);
    return () => clearTimeout(clearErrorTimeout);
  }, [errors.password]);

  useEffect(() => { //reset email error
    const clearErrorTimeout = setTimeout(() => {
      clearErrors("email");
    }, 3000);
    return () => clearTimeout(clearErrorTimeout);
  }, [errors.email]);

  return (
    <div className="signin">
      <h4 className="signin__title">Sign In:</h4>
      <form onSubmit={handleSubmit(handleSignInForm)}>
        {isLoading && <p>Loading...</p>}
        <input
          placeholder="e-mail:"
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
          aria-invalid={errors.email ? "true" : "false"}
        />
        <input
          type="password"
          name="password"
          placeholder="password"
          {...register("password", {
            required: "password is required",
          })}
        />
        <div className="signin__control">
          <button onClick={goBackButton} className="signin__button">Back</button>
          <input
            disabled={!isValid}
            className={!isValid ? "disabled" : ""}
            type="submit"
            value="Sign In"
          />
        </div>
        {((errors.email || errors.password)|| errorMessage) && (
          <div className="errors">
            {errors.email &&  <div>{errors.email.message}</div>}
            {errors.password && <div>{errors.password.message}</div>} 
            {errorMessage && <div>{errorMessage}</div>} 
          </div>
        )}

      </form>
    </div>
  );
};
export default LogIn;
