import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const URL = process.env.REACT_APP_URL
export const getPosts = createAsyncThunk(
  'posts/fetchPosts',
  async (params, thunkAPI) => {
    const response = await axios.get(`${URL}news`, {
      params,
    });
    return response.data;
  }
);

export const postPosts = createAsyncThunk(
  'posts/postPosts',
  async (data, thunkAPI) => {
    const response = await axios.post(`${URL}posts`, data);
    return response.data;
  }
);

const postsSlice = createSlice({
  name: 'postsList',
  initialState: {
    posts: [],
    isLoading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts = action.payload;
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  // Add any additional actions here
} = postsSlice.actions;

export default postsSlice.reducer;


