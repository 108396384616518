import Test from "./Test";
import Games from "./Games";
import LessonsList from "./Lessons/lessonsList";
import LessonId from "./Lessons/lessonId";
import Words from "./Words";
import User from "../pages/User";
import Auth from "../components/Auth";
import { useSelector, useDispatch } from "react-redux";
import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import Home from "./Home";
import NewsId from "../components/News/newsId";
import SingleWord from "./Words/singleWord";

const Router = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();

  const { isAuthenticated } = useSelector((state) => state.user);
  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        {/* <Route path="*" element={<Navigate to="not-found"/>} /> */}
        <Route path="/" element={<Navigate to={`${i18n.resolvedLanguage}/`} />}/>
        <Route exact path={`${i18n.resolvedLanguage}/`} element={<Home />} />
        <Route path=":locale/profile" element={isAuthenticated ? <User /> : <Navigate to={`/${lang}/auth`} replace />} />
        <Route path={"/:locale/u/:id"} element={<User />} />
        <Route path={":locale/test"} element={<Test />} />
        <Route path={":locale/games"} element={<Games />} />
        <Route path={":locale/:id"} element={<NewsId />} /> 
        <Route path={":locale/:id/:title"} element={<NewsId />} />
        <Route path={":locale/lessons"} element={<LessonsList />} />
        <Route path={":locale/lessons/:id"} element={<LessonId />} />
        <Route path={":locale/lessons/:id/:title"} element={<LessonId />} />
        <Route path={":locale/words"} element={<Words />} />
        <Route path={":locale/words/:id"} element={<SingleWord />} />
        <Route path={":locale/auth"} element={<Auth />} />
      </Routes>
    </AnimatePresence>
  );
};
export default Router;