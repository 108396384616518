
import './index.scss';
import { useState, useEffect } from 'react';
import { useLogInMutation } from '../../services/nalle_api';
import Cat from '../../components/Cat';
import Comments from '../../components/News/comments';
export default function Test(){

    const [email, setEmail] = useState('test');
    const [password, setPassword] = useState('test 2');


    const [logIn, result] = useLogInMutation()

    const handleLogin = (e) => {
        e.preventDefault();
        logIn({ email, password });
        console.log(email, password);
        console.log(result)
    };
  
    return (
        <div className="main bg-dark">
            <button onClick={handleLogin}>TEST</button> 
            ...
            <Cat />
            ...
            <Comments news_id={1} />
            <Comments news_id={1} />
        </div>
    );
}
