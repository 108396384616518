import { createSlice, current } from "@reduxjs/toolkit";

const menuItemsPosts = [
  { label: "Remove", link: "/remove", showId: true },
  { label: "Item 2", link: "/item2", showId: false },
  { label: "New item in ID", link: "/item3", showId: true },
];
const menuItemsComments = [
  { label: "Copy link", link: "#", showId: false },
  { label: "Copy comment text", link: "#", showId: false },
  { label: "Report comment", link: "#", showId: false },
  { label: "Remove", link: "/remove", showId: true },
  { label: "Item 2", link: "/item2", showId: false },
];
const initialState = {
  showOverlay: false,
  openOptionsMenu: false,
  currentId: null,
  menuItemsPosts,
  menuItemsComments,
  menuType: "news",
};
const overlaySlice = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    toggleOverlay: (state) => {
      state.showOverlay = !state.showOverlay;
    },
    setOverlay: (state, action) => {
      state.showOverlay = action.payload;
    },
    setCurrentId: (state, action) => {
      state.currentId = action.payload;
    },
    setMenuType: (state, action) => {
      state.menuType = action.payload;
    },
  },
});

export const { toggleOverlay, setOverlay, setCurrentId, setMenuType } = overlaySlice.actions;
export default overlaySlice.reducer;
