import React from 'react';
import {useDraggable} from '@dnd-kit/core';
export default function Draggable(props) {
  const {attributes, listeners, setNodeRef, isDragging, transform} = useDraggable({
    id: props.id,
  });
//   console.log(isDragging) // element is active
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  
  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes} className='select-one__word'>
      {props.children}
    </button>
  );
}