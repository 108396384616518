
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation} from 'react-router-dom';
import './index.scss'
import { useState, useEffect } from "react";

const Language = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { i18n } = useTranslation();
    
    const [dropdownLang, setDropdownLang] = useState(i18n.language || 'en');
    useEffect(() => {
        const changeLanguage = (lng) => {
            setDropdownLang(lng);
        };
        i18n.on('languageChanged', changeLanguage);
        return () => {
          i18n.off('languageChanged', changeLanguage);
        };
      }, [i18n]);
    
    const languageHandler = event => {
        const newLanguage = event.target.value;
        if (dropdownLang !== newLanguage) {
          setDropdownLang(newLanguage);
          i18n.changeLanguage(newLanguage);
          const newPath = location.pathname.replace(`/${dropdownLang}/`, `/${newLanguage}/`);
          navigate(newPath);
          document.documentElement.setAttribute("lang", newLanguage);
        }
    };

    return (
        <select className="language" value={dropdownLang} onChange={languageHandler}>
            <option value="en">EN</option>
            <option value="ru">RU</option>
        </select>
        
    );
};

export default Language;


// const newLanguage = event.target.value;
// if (dropdownLang !== newLanguage) {
//   setDropdownLang(newLanguage);
//   i18n.changeLanguage(newLanguage);

  
//   navigate("/" + newLanguage + location.pathname);
//   document.documentElement.setAttribute("lang", newLanguage);
// }