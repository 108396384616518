import './index.scss';
import News from '../../components/News/news';
import { useGetNewsQuery } from '../../services/data';
import { useSelector } from 'react-redux';
import Cat from '../../components/Cat';

const Home = () => {
  const { myId } = useSelector(({ user }) => user);
  const { data, error, isLoading } = useGetNewsQuery(myId);
  return (
    isLoading ? <Cat /> :
    error ? <div>Error: ({error.status})</div> :
    !data ? <div>No data available</div> :
    <News data={data} />
  )
} 
export default Home
