import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader } from '../_helpers/auth-header'
const URL = process.env.REACT_APP_URL

export const data = createApi({
  reducerPath: 'data',
  baseQuery: fetchBaseQuery({ baseUrl: URL }),
  tagTypes: ['News', 'Words', 'WordsCategories', 'Lessons ', 'Likes'],
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({
        url: `news`,
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
      }),
      providesTags: ['News'],
    }),
    getNewsById: builder.query({
        query(id) {
          return {
            url: `news/${id}`,
            method: 'GET',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
          };
        }
    }),
    getWordById: builder.query({
      query(id) {
        return {
          url: `words/${id}`,
          method: `GET`,
        }
      }
    }),
    getWordsCategories: builder.query({
      query() {
        return {
          url: `words/categories/`,
          method: `GET`,
        }
      }
    }),
    getWordsCategoryById: builder.query({
      query(id) {
        return {
          url: `words/categories/${id}`,
          method: `GET`,
          // headers: {'Content-Type': 'application/json' },
        }
      }
    }),
    getLessons: builder.query({
      query() {
        return {
          url: `lessons`,
          method: `GET`,
        }
      }
    }),
    getLessonById: builder.query({
      query(id) {
        return {
          url: `lessons/${id}`,
          method: `GET`,
          responseHandler: (response) => {
            if (!response.ok) {
              return response.json().then((errorData) => {
                throw new Error(errorData.message);
              });
            } else {
              return response.json()
            }
          }
        }
      }
    }),
    getComments: builder.query({
      query(id) {
        return {
          url: `comments/${id}`,
          method: `GET`,
          responseHandler: (response) => {
            if (!response.ok) {
              return response.json().then((errorData) => {
                throw new Error(errorData.message);
              });
            } else {
              return response.json()
            }
          }
        }
      }
    }),
    addComment: builder.mutation({
      query: (data) => ({
        url: `add_comment`, 
        method: `POST`,
        headers: { 'Content-Type': 'application/json',},
        body: JSON.stringify(data),
      }),
      // invalidatesTags: ['News'],
    }),
    updateLike: builder.mutation({
      query: (data) => ({
        url: `likes`, 
        method: `POST`,
        headers: { 'Content-Type': 'application/json',},
        body: JSON.stringify(data),
      }),
      // invalidatesTags: ['News'],
    }),
  }),
});
export const { useGetNewsQuery, 
               useGetNewsByIdQuery,
               useGetWordByIdQuery,
               useGetWordsCategoriesQuery,
               useGetWordsCategoryByIdQuery,
               useGetLessonsQuery,
               useGetLessonByIdQuery,
               useUpdateLikeMutation,
               useGetCommentsQuery,
               useAddCommentMutation
             } = data

