import { NavLink } from "react-router-dom";
import Search from "../../components/Search";
import { useSelector } from "react-redux";
const Words = () => {
    const { data, status } = useSelector((state) => state.dataList);
    return (
        <div>
          {/* <Search placeholder="Enter a word..." data={data} /> */}
          <ul>
          {data.map((item, i) => {
            return <li key={i}><NavLink to={item.word}>{item.word}</NavLink></li>;
          })}
          </ul>
          <p>{status}</p>
        </div>
    )
}
export default Words