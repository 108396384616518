import { useSelector, useDispatch } from "react-redux";
import { logoutSuccess, setUser } from '../../features/userSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Settings from '../../components/Settings'
import "./index.scss"
import { useEffect, useState, useEffectAsync} from "react";
import { useParams } from 'react-router-dom';
import { useGetUserByIdQuery, useGetAllUsersQuery } from '../../services/nalle_api';
import Loading from "../../components/Loading";
import Skeleton from "react-loading-skeleton";
import { Suspense } from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import Cat from "../../components/Cat";
const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { id } = useParams()


  const { isAuthenticated, token, myLogin, myId, userData} = useSelector((state) => state.user);
  const [user, setUser] = useState(null); //store data from token or from DB 
  const isOwnProfile = id === userData?.login; // is URL ID the same as login from token?
  const userId = !isOwnProfile ? id : null; //take ID from URL for other users
  const { data, error, isLoading } = useGetUserByIdQuery(userId, {
      skip: userId === undefined || isOwnProfile, // Skip the query if userId is undefined or it's the user's own profile
  });
  const isProfilePage = userId === undefined; //is it Profile Page? (don't mess with User Page)
 useEffect(() => {
     if (isOwnProfile || isProfilePage) { //user own page
         setUser(userData); //set data from token
     } else if (data) { //other user page
        setUser(data); //set data from DB
     }
     else if (!data) { //if user not founded
      setUser({
        login: "DELETED",
        img: "/images/deleted.jpeg",
     })
     }
 }, [isOwnProfile, id, data, userData]);

  function logOut() {
    dispatch(logoutSuccess());
    navigate(`/${lang}/`);
  }

  const testButton = (token, myId, myLogin) => {
  };
  if(isLoading) {
    return <Cat />
  }
  // else if(!isLoading && !isOwnProfile &&!isProfilePage && !data) { //in case we want a different page for deleted user
  //   return <p>user has not been found</p>
  // }
  else {
    return (
      <div className="user">
        <div className="user__profile">
          <div className="user__img">
            <img src={user?.img} alt="" />
          </div>
          <div className="user__login">{user?.login}</div>
          <div className="user__name">{user?.name}</div>
          <div className="user__date">{user?.date}</div>
        </div>
        {isAuthenticated && isProfilePage &&
          <ul className="user__list">
            <li className="user__item">
              <a href="#" onClick={() => testButton(token, myId, myLogin)} className="user__link">Test button</a>
            </li>
            <li className="user__item">
              <a href="#" className="user__link"><Settings /></a>
            </li>
            <li className="user__item">
              <button onClick={logOut} className="user__link">Sign out</button>
            </li> 
        </ul>}
      </div>
    )
  }


  // // skeleton
  // return (
  //   <div className="user">
  //     <div className="user__user">
      
  //       <div className="user__img">
  //       {data ? 
  //         <img src={data.img} alt="" />
  //         : <Skeleton circle={true} />}
  //       </div>
        
  //       <div className="user__login">{data ? data.login : <Skeleton />}</div>
  //       <div className="user__name">{data ? data.name : <Skeleton />}</div>
  //     </div>
  //     {isAuthenticated && myLogin === data?.login && //is current user page === authenticated user
  //       <ul className="user__list">
  //         <li className="user__item">
  //           <a href="#" className="user__link"><Settings /></a>
  //         </li>
  //         <li className="user__item">
  //           <button onClick={logOut} className="user__link">Sign out</button>
  //         </li> 
  //     </ul>}
  //     <Skeleton circle={true} width={50} />

  //   </div>
  // )
}
export default User

// Laakso Santeri LAASA