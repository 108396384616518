import { createSlice } from "@reduxjs/toolkit";
import { decodeToken } from '../utils';
// console.log(Math.ceil(decodeToken(localStorage.getItem('token')).date.getTime()))

const initialState = {
  loading: false,
  error: null,
  success: false,
  token: null,
  decodedToken: null, //decode token
  myId: localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).id : null,
  myLogin: localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).login : null,
  myImg: localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).img : null,
  isAuthenticated: localStorage.getItem('token') ? true : false,  //check if token exist
  userData: localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')) : null,
  date: localStorage.getItem('token') ? localStorage.getItem('token').date : "",
  // date: localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).date : "",
  name: null,
  email: null,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserById: (state, action) => {
      state.user = action.payload
    },
    // setUser: (state, action) => {
    //   state.date = action.payload
    // },
    clearUser: (state) => {
      state.userInfo = null;
    },
    loginSuccess(state) {
      state.success = true;
      state.isAuthenticated = true;
      state.token = localStorage.getItem('token');
      state.decodedToken = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')) : ""; //decode token
      state.myId = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).id : "";
      state.myLogin = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).login : "";
      state.myImg = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).img : "";
      state.userDate = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')).date : "";
      state.userData = localStorage.getItem('token') ? decodeToken(localStorage.getItem('token')) : null;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      sessionStorage.removeItem("storageKey");
      state.decodedToken = null;
      state.myId = null;
      state.myLogin = null;
      state.loading = false;
      state.userInfo = null;
      state.error = null;
    },
  },
});

export const selectUser = (state) => state.user;
export const { getUserById, setUser, clearUser, loginSuccess, logoutSuccess } = userSlice.actions;
export default userSlice.reducer;
