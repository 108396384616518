
import { useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import { useAddCommentMutation } from '../../services/data';

const WriteComment = ({news_id, news_title, openForm, handleForm}) => {

  const { isAuthenticated, myId } = useSelector((state) => state.user);
  const [commentText, setCommentText] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [addComment, { isLoading: addCommentLoading, error: addCommentError }] = useAddCommentMutation();
  
  // useEffect(() => {
  //   if (openForm) {
  //       document.body.classList.add('no-scroll');
  //   } else {
  //       document.body.classList.remove('no-scroll');
  //   }
  // }, [openForm]);

  const handleTextChange = (e) => {
    setCommentText(e.target.value);
    setIsValid(e.target.value.trim().length > 0);
};
  const addCommentHandle = async (e) => {
    console.log("addCommentHandle");
    e.preventDefault();
    if (!isAuthenticated) {
        console.log("please log in!");
    } else {
        try {
            handleForm();
            await addComment({
                news_id: news_id,
                user_id: myId,
                text: commentText,
            });
            setCommentText('');
            setIsValid(false);
        } catch (error) {
            console.error("Failed to add comment", error);
        }
    }
};
  return (
    <form onSubmit={addCommentHandle} className={`comments__form`}>
      <div onClick={handleForm} className="comments__close"></div>
      <div className="comments__post-title">{news_title}</div>
      <textarea
        type="text"
        className="comments__textarea"
        value={commentText}
        onChange={handleTextChange}
      />
      <input
        disabled={!isValid}
        className={`comments__submit ${!isValid ? "disabled" : ""}`}
        type="submit"
        value="Submit"
      />
    </form>
  )
}
export default WriteComment;