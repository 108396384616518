import { useState, useEffect } from 'react';
export default function Settings() {
  const [open, setOpen] = useState('')
  const handleToggle = () => {
      setOpen(!open)
  }
    return (
        <>
        <button onClick={handleToggle}>Настройки</button>
        <span>{open ? 'true' : 'false'}</span>
        </>
    )
}