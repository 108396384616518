import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const URL = process.env.REACT_APP_URL

export const getData = createAsyncThunk(
  'data/fetchData',
  async (params, thunkAPI) => {
    const response = await axios.get(`${URL}words/`, {
      params,
    });
    return response.data;
  }
);

export const getWordsCategory = createAsyncThunk(
  'data/fetchData',
  async (params, thunkAPI) => {
    const response = await axios.get(`${URL}words/categories`, {
      params,
    });
    return response.data;
  }
);


export const postData = createAsyncThunk(
  'data/postData',
  async (data, thunkAPI) => {
    const response = await axios.post(`${URL}words`, data);
    return response.data;
  }
);

const dataSlice = createSlice({
  name: 'dataList',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    categories: [],
  },
  reducers: {
    deleteCard: (state, action) => {
      const id = action.payload;
      state.data = state.data.filter(card => card.id !== id);
      console.log(`reducer got ID: ${id}`)
    },
    setCategory: (state, action) => {
      state.monthsData = action.payload;
    },
    iKnow: async (state, action) => {
      const cardId = action.payload;
      try {
        await postData({ cardId });
        console.log('User has pressed Iknow');
      } catch (error) {
        console.error(error);
      }
    },
  },
  extraReducers: {
    [getData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      // state.category = action.payload.filter(cat_en); // фильтруем данные для monthsData
      // state.monthsData = action.payload.filter(card => card.cat_en === 'month'); // фильтруем данные для monthsData
    },
    [getData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const {
  deleteCard,
  iKnow,
  setMonthsData
} = dataSlice.actions;

export default dataSlice.reducer;