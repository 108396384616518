import React, { useEffect, Suspense, lazy, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetNewsByIdQuery } from '../../services/data';
import { motion } from 'framer-motion';
import Overlay from './Overlay';
import Cat from '../Cat';
import "./index.scss";
import Comments from './comments';
import Post from './post';

const NewsId = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetNewsByIdQuery(id);

  if (isLoading) {
    return <Cat />;
  }
  if (error) {
    return <div>Error: ({error.status})</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}>
      <Post
        key={data.id}
        id={data.id}
        author={data.author}
        title={data.title_en}
        text={data.text_en}
        date={data.date}
        likes={data.likes}
        liked={data.user_liked}
        titleIsLink={false}
        showFullText={true}
        comments={data.comments}
      />
      <Comments news_id={id} news_title={data.title_en}/>
    </motion.div>
  );
};

export default NewsId;