import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { convertToURL, postDate } from "../../utils";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { useUpdateLikeMutation } from "../../services/data";
import { CommentSVG } from "../../svgs";
import { useSelector, useDispatch } from 'react-redux';
import { toggleOverlay, setCurrentId, setMenuType } from '../../features/overlaySlice';

const Post = ({ id, author, title, text, date, likes, openOptions, isOpen, titleIsLink, showFullText, liked, comments }) => {
  const { t } = useTranslation();
  const lang = i18n.language;

  const dispatch = useDispatch();
  const showOverlay = useSelector((state) => state.overlay.showOverlay);

  const handleOverlayToggle = () => {
    dispatch(toggleOverlay());
    dispatch(setCurrentId(id));
    dispatch(setMenuType("news"));
    document.body.classList.add('no-scroll');
  };

  // show more button
  const [expanded, setExpanded] = useState(false);
  const toggleShowMore = () => {
    setExpanded(!expanded);
  };

  // like button
  const [isLiked, setLiked] = useState(liked);
  const [likesCount, setLikesCount] = useState(() => {
    const savedLikes = JSON.parse(localStorage.getItem('likesCounts')) || {};
    return savedLikes[id] ? parseInt(savedLikes[id]) : parseInt(likes) || 0;
  });

  const { isAuthenticated, myId } = useSelector((state) => state.user);
  const [updateLike, { isLoading, error }] = useUpdateLikeMutation();

  const handleCheckboxChange = async (newsId, myId) => {
    if (!isAuthenticated) {
      console.log("please log in!");
    } else {
      try {
        const action = isLiked === "1" ? "dislike" : "like";
        const newLikes = action === "like" ? likesCount + 1 : likesCount - 1;
        setLikesCount(newLikes); // Update likes count state
        setLiked(action === "like" ? "1" : "0"); // Update liked state

        // handle local storage with likes
        const savedLikes = JSON.parse(localStorage.getItem('likesCounts')) || {};
        if (action === "dislike") {
          delete savedLikes[newsId]; // Remove the like from local storage
        } else {
          savedLikes[newsId] = newLikes; // Update the like count in local storage
        }
        localStorage.setItem('likesCounts', JSON.stringify(savedLikes));
        // end of handle local storage with likes

        await updateLike({
          action,
          news_id: newsId,
          user_id: myId,
        });
      } catch (error) {
        console.error("Failed to update like status", error);
      }
    }
  };

  return (
    <div id={id} className={`post${expanded ? " open" : ""}`}>
      <div className="post__header">
        <div className="post__info">
          <Link to={`/${lang}/u/${author}`} className="post__author">
            {author}
            <span>, </span>
          </Link>
          <div className="post__date">{postDate(date)}</div>
        </div>
          <div
            // onClick={() => openOptions(id)}
            onClick={() => handleOverlayToggle()}
            className={`options${showOverlay ? " open" : ""}`}
          >
            <span></span>
          </div>
      </div>
      {/* show title as link */}
      {titleIsLink ? (
        <Link to={`${id}/${convertToURL(title)}`} className="post__title">
          {title}
        </Link>
      ) : (
        <div className="post__title">{title}</div>
      )}
      {/* show full text */}
      {showFullText ? (
        <div className={`post__text`}>{text}</div>
      ) : (
        <div
          className={`post__text${
            text.length > 500 && !expanded ? " cropped" : ""
          }`}
        >
          {expanded ? text : `${text.slice(0, 500)}...`}
        </div>
      )}

      {text.length > 500 && !expanded && !showFullText && (
        <button onClick={toggleShowMore} className="post__button">
          {t("read-more")}
        </button>
      )}
      <div className="post__footer">
        <div className="heart">
          <div
            onClick={() => handleCheckboxChange(id, myId)}
            className={`heart__icon ${isLiked === "1" ? " checked" : ""}`}
          >
            <svg
              className="heart__svg"
              viewBox="467 392 58 57"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                className="heart__group"
                fill="none"
                fillRule="evenodd"
                transform="translate(467 392)"
              >
                <path
                  d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                  className="heart__path"
                  fill="#AAB8C2"
                />
                <circle
                  className="heart__main"
                  fill="#E2264D"
                  opacity="0"
                  cx="29.5"
                  cy="29.5"
                  r="1.5"
                />
                <g
                  className="heart__group7"
                  opacity="0"
                  transform="translate(7 6)"
                >
                  <circle
                    className="heart__group7-oval1"
                    fill="#9CD8C3"
                    cx="2"
                    cy="6"
                    r="2"
                  />
                  <circle
                    className="heart__group7-oval2"
                    fill="#8CE8C3"
                    cx="5"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group6"
                  opacity="0"
                  transform="translate(0 28)"
                >
                  <circle
                    className="heart__group6-oval1"
                    fill="#CC8EF5"
                    cx="2"
                    cy="7"
                    r="2"
                  />
                  <circle
                    className="heart__group6-oval2"
                    fill="#91D2FA"
                    cx="3"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group3"
                  opacity="0"
                  transform="translate(52 28)"
                >
                  <circle
                    className="heart__group3-oval2"
                    fill="#9CD8C3"
                    cx="2"
                    cy="7"
                    r="2"
                  />
                  <circle
                    className="heart__group3-oval1"
                    fill="#8CE8C3"
                    cx="4"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group2"
                  opacity="0"
                  transform="translate(44 6)"
                >
                  <circle
                    className="heart__group2-oval2"
                    fill="#CC8EF5"
                    cx="5"
                    cy="6"
                    r="2"
                  />
                  <circle
                    className="heart__group2-oval1"
                    fill="#CC8EF5"
                    cx="2"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group5"
                  opacity="0"
                  transform="translate(14 50)"
                >
                  <circle
                    className="heart__group5-oval1"
                    fill="#91D2FA"
                    cx="6"
                    cy="5"
                    r="2"
                  />
                  <circle
                    className="heart__group5-oval2"
                    fill="#91D2FA"
                    cx="2"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group4"
                  opacity="0"
                  transform="translate(35 50)"
                >
                  <circle
                    className="heart__group4-oval1"
                    fill="#F48EA7"
                    cx="6"
                    cy="5"
                    r="2"
                  />
                  <circle
                    className="heart__group4-oval2"
                    fill="#F48EA7"
                    cx="2"
                    cy="2"
                    r="2"
                  />
                </g>
                <g
                  className="heart__group1"
                  opacity="0"
                  transform="translate(24)"
                >
                  <circle
                    className="heart__group1-oval1"
                    fill="#9FC7FA"
                    cx="2.5"
                    cy="3"
                    r="2"
                  />
                  <circle
                    className="heart__group1-oval2"
                    fill="#9FC7FA"
                    cx="7.5"
                    cy="2"
                    r="2"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className={`heart__count${isLiked === "1" ? " liked" : ""}`}>
            {likesCount}
          </div>
        </div>
        {titleIsLink ? (
          <Link to={`${id}/${convertToURL(title)}#comments`} className="post__comments">
            <div className="post__icon">
              <CommentSVG />
            </div>
            <div className="post__count">{comments}</div>
          </Link>
        ) : (
          <div className="post__comments">
            <div className="post__icon">
              <CommentSVG />
            </div>
            <div className="post__count">{comments}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;