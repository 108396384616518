import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export default function Droppable(props, result) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  return (
    <div ref={setNodeRef} className={`select-one__answer${isOver ? ' over' : ''}`}>
      {props.children}
    </div> 
  );
}

{/* <div ref={setNodeRef} className={`select-one__answer${isOver ? ' over' : ''}`}>
{props.children}<div className="select-one__shade">{props.id}</div> 
</div> */}