import './index.scss'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Search from '../Search'
const Footer = () => {
  const { i18n } = useTranslation();
  const { data, status } = useSelector((state) => state.dataList);
  return (
      <footer className='footer'>
      <Search data={data} />
        <nav className='footer__nav'>
          <ul className='footer__list'>
            <li className='footer__item'>
              <NavLink to={i18n.resolvedLanguage + "/"} className="footer__link">
                {/* <svg fill="currentColor" viewBox="0 0 22 22">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                </svg> */}
                <svg  fill="currentColor" viewBox="0 0 576 512"><path d="M576 256c0 18-15 32-32 32h-32v184c0 22-18 40-40 40h-80c-22 0-40-18-40-40v-88c0-18-14-32-32-32h-64c-18 0-32 14-32 32v88c0 22-18 40-40 40h-80c-22 0-40-18-40-40V288H32c-18 0-32-14-32-32 0-9 3-17 10-24L266 8c7-7 15-8 22-8s15 2 21 7l256 225c8 7 12 15 11 24z"/></svg>
                <span>Home</span>
              </NavLink>
            </li>
            <li className='footer__item'>
              <NavLink to={i18n.resolvedLanguage + "/games"} className="footer__link">
                {/* <svg fill="currentColor" viewBox="0 0 22 22">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                </svg> */}
                <svg fill="currentColor" viewBox="0 0 576 512"><path d="M576 256c0 18-15 32-32 32h-32v184c0 22-18 40-40 40h-80c-22 0-40-18-40-40v-88c0-18-14-32-32-32h-64c-18 0-32 14-32 32v88c0 22-18 40-40 40h-80c-22 0-40-18-40-40V288H32c-18 0-32-14-32-32 0-9 3-17 10-24L266 8c7-7 15-8 22-8s15 2 21 7l256 225c8 7 12 15 11 24z"/></svg>
                <span>Games</span>
              </NavLink>
            </li>
            <li className='footer__item'>
              <NavLink to={i18n.resolvedLanguage + "/lessons"} className="footer__link">
                {/* <svg fill="currentColor" viewBox="0 0 22 22">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> */}
                <svg fill="currentColor" viewBox="0 0 576 512"><path d="M576 256c0 18-15 32-32 32h-32v184c0 22-18 40-40 40h-80c-22 0-40-18-40-40v-88c0-18-14-32-32-32h-64c-18 0-32 14-32 32v88c0 22-18 40-40 40h-80c-22 0-40-18-40-40V288H32c-18 0-32-14-32-32 0-9 3-17 10-24L266 8c7-7 15-8 22-8s15 2 21 7l256 225c8 7 12 15 11 24z"/></svg>
                <span>Lessons</span>
              </NavLink>
            </li>
            <li className='footer__item'>
              <NavLink to={i18n.resolvedLanguage + "/words"} className="footer__link">
                {/* <svg fill="currentColor" viewBox="0 0 690 690"><path d="M368 32a16 16 0 0 0 0-32H96C43 0 0 43 0 96v272a16 16 0 0 0 32 0V96c0-35 29-64 64-64h272Zm48 64H160c-35 0-64 29-64 64v256c0 35 29 64 64 64h165c17 0 34-7 46-19l90-90c12-12 19-29 19-46V160c0-35-29-64-64-64Zm-64 339v-67c0-9 7-16 16-16h67l-83 83Zm96-115h-80c-26 0-48 22-48 48v80H160c-18 0-32-14-32-32V160c0-18 14-32 32-32h256c18 0 32 14 32 32v160Z"/></svg> */}
                <svg fill="currentColor" viewBox="0 0 576 512"><path d="M576 256c0 18-15 32-32 32h-32v184c0 22-18 40-40 40h-80c-22 0-40-18-40-40v-88c0-18-14-32-32-32h-64c-18 0-32 14-32 32v88c0 22-18 40-40 40h-80c-22 0-40-18-40-40V288H32c-18 0-32-14-32-32 0-9 3-17 10-24L266 8c7-7 15-8 22-8s15 2 21 7l256 225c8 7 12 15 11 24z"/></svg>
                <span>Words</span>
              </NavLink>
            </li>
            <li className='footer__item'>
              <NavLink to={i18n.resolvedLanguage + "/profile"} className="footer__link" >
                {/* <img src="https://assets.puzzlefactory.com/puzzle/254/191/original.jpg" alt="user avatar" /> */}
                <svg fill="currentColor" viewBox="0 0 576 512"><path d="M576 256c0 18-15 32-32 32h-32v184c0 22-18 40-40 40h-80c-22 0-40-18-40-40v-88c0-18-14-32-32-32h-64c-18 0-32 14-32 32v88c0 22-18 40-40 40h-80c-22 0-40-18-40-40V288H32c-18 0-32-14-32-32 0-9 3-17 10-24L266 8c7-7 15-8 22-8s15 2 21 7l256 225c8 7 12 15 11 24z"/></svg>
                <span>Profile</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </footer>
  )
}
export default Footer