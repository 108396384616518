import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import './index.scss'
import { useSelector, useDispatch } from "react-redux";
import { selectNav, openButton } from "../../features/navSlice";


const Menu = () => {
    const { i18n } = useTranslation();
    const nav = useSelector(selectNav)
    const dispatch = useDispatch()
    return (
        <nav className={`menu${nav.isOpen ? " open" : ""}`}>
            <ul className='menu__list'>
                <li className='menu__item'><NavLink onClick={(e) => dispatch(openButton(false))} to={i18n.resolvedLanguage + "/"} className='menu__link'>home</NavLink></li>
                <li className='menu__item'><NavLink onClick={(e) => dispatch(openButton(false))} to={i18n.resolvedLanguage + "/test"} className='menu__link'>test</NavLink></li>
            </ul>
        </nav>
    )
    
} 
export default Menu