import React, { useState } from "react";
import { useGetCommentsQuery } from "../../services/data";
import WriteComment from "./writeComment";
import Comment from "./comment";
import "./index.scss";
import { Link } from "react-router-dom";
const Comments = ({ news_id, news_title }) => {
  const [openForm, setOpenForm] = useState(false);
  const handleForm = () => {
    setOpenForm(!openForm);
  };
  const { data, error, isLoading } = useGetCommentsQuery(news_id);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: ({error.status})</div>;
  return (
    <div className="comments">
      <div className="comments__info">
        <h3 id="comments">Comments</h3>
        <button type="button"
          onClick={handleForm} 
          className={`comments__add-btn ${openForm ? "open" : ""}`}
        >
          {openForm ? "-" : "+"}
        </button>
      </div>
      {openForm && (
        <WriteComment
          openForm={openForm}
          news_id={news_id}
          news_title={news_title}
          handleForm={handleForm}
        />
      )}
      {data.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export default Comments;
