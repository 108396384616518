import React, { useState, useEffect, useMemo, useRef } from "react";
import TinderCard from "react-tinder-card";
import "./index.scss";
import { UndoSVG } from "../../../svgs";
import CardId from "../../Card/cardId";
import Cat from "../../Cat";
import {
  useGetWordsCategoriesQuery,
  useGetWordsCategoryByIdQuery,
} from "../../../services/data";
function FlashCards(category) {
  const { data, isError, isLoading } = useGetWordsCategoryByIdQuery(category?.category);
  if(category?.category === 'my-words') {
    const myWordsStorage = localStorage.getItem('imported-keys') ? JSON.parse(localStorage.getItem('imported-keys')) : [];
    if(myWordsStorage.length === 0) { 
      return <p>There are no words</p>
    }
    else {
      return <Game initialState={myWordsStorage} />
    }
  }
  if (isLoading) {
    return <Cat />
  }
  if (isError) {
    return <p>Oops, failed to load</p>
  }
  return <Game initialState={data} />
  
  function Game({ initialState }) {
    console.log(initialState)
    const [currentIndex, setCurrentIndex] = useState(initialState.length - 1);
    const [lastDirection, setLastDirection] = useState(); //Get last swipe
    const [userLike, setUserLike] = useState(null); //User set the like
    const [userDisLike, setUserDisLike] = useState([]); //User set the dislike
    const [activeIndex, setActiveIndex] = useState(null); // Track active index
  
    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex);
    // For control buttons
    const canGoBack = currentIndex < initialState.length - 1;
    const canSwipe = currentIndex >= 0;
  
    const childRefs = useMemo(
      () =>
        Array(initialState.length)
          .fill(0)
          .map((i) => React.createRef()),
      []
    );
  
    const updateCurrentIndex = (val) => {
      setCurrentIndex(val);
      currentIndexRef.current = val;
      setActiveIndex(null); // Reset active index when updating
      setUserLike(null); //Reset like index like
    };
    // Function to reset active index after a delay
    const resetActiveIndex = () => {
      setTimeout(() => {
        setActiveIndex(null);
        setUserLike(null);
      }, 1000); // Adjust the delay as per your requirement
    };
  
    // set last direction and decrease current index
    const swiped = (direction, name, index) => {
      setLastDirection(direction);
      console.log(`dir on: ${direction}`);
      updateCurrentIndex(index - 1);
      // actionOnSwipe(direction, name)
    };
    // function actionOnSwipe(direction, name) {
    //   if(direction === 'right') {
    //     setUserLike(userLike => [...userLike, name])
    //   }
    // }
  
    //card left the screen
    const outOfFrame = (name, idx) => {
      console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
      // handle the case in which go back is pressed before card goes outOfFrame
      currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
      // TODO: when quickly swipe and restore multiple times the same card,
      // it happens multiple outOfFrame events are queued and the card disappear
      // during latest swipes. Only the last outOfFrame event should be considered valid
    };
  
    const swipe = async (dir) => {
      if (canSwipe && currentIndex < initialState.length) {
        await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
      }
    };
  
    // increase current index and show card
    const goBack = async () => {
      if (!canGoBack) return;
      const newIndex = currentIndex + 1;
      updateCurrentIndex(newIndex);
      await childRefs[newIndex].current.restoreCard();
    };
    
    return (
      <div className="flash-cards">
        <div className="flash-cards__cards">
            {initialState.map((character, index) => (
              <TinderCard
                ref={childRefs[index]}
                className={`flash-cards__card ${
                  activeIndex === index ? "active" : ""
                } ${userLike === index ? "like" : ""}`}
                key={character.id}
                swipeRequirementType="position"
                onSwipe={(dir) => {
                  swiped(dir, character.id, index);
                  setActiveIndex(index); // Update active index on swipe
                  resetActiveIndex(); // Reset active index after swipe
  
                  if (dir === "right") {
                    setUserLike(index);
                  }
                  if (dir === "left") {
                    console.log("swipe was left");
                  }
                }}
                onCardLeftScreen={() => outOfFrame(character.name, index)}
              >
                <CardId img={character.img} word={character.word} />
              </TinderCard>
            ))}
        </div>
        <div className="flash-cards__buttons">
          <button
            className="flash-cards__buttons-left"
            style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
            onClick={() => swipe("left")}
          >
            Swipe left!
          </button>
          <button
            className={`flash-cards__buttons-undo ${!canGoBack ? " hidden" : ""}`}
            style={{ backgroundColor: !canGoBack && "#c3c4d3" }}
            onClick={() => goBack()}
          >
            <UndoSVG />
          </button>
          <button
            className="flash-cards__buttons-right"
            style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
            onClick={() => swipe("right")}
          >
            Swipe right!
          </button>
        </div>
        {/* {lastDirection && (
          <h2 key={lastDirection} className="infoText">
            Last swipe is: {lastDirection}
          </h2>
        )} */}
      </div>
    );
    
  }
  
}


//   // const { data, isLoading, categories } = useSelector((state) => state.dataList);
  
//   const [currentIndex, setCurrentIndex] = useState(data.length - 1);
//   const [lastDirection, setLastDirection] = useState(); //Get last swipe
//   const [userLike, setUserLike] = useState(null); //User set the like
//   const [userDisLike, setUserDisLike] = useState([]); //User set the dislike
//   const [activeIndex, setActiveIndex] = useState(null); // Track active index

//   // used for outOfFrame closure
//   const currentIndexRef = useRef(currentIndex);
//   // For control buttons
//   const canGoBack = currentIndex < data.length - 1;
//   const canSwipe = currentIndex >= 0;

//   const childRefs = useMemo(
//     () =>
//       Array(data.length)
//         .fill(0)
//         .map((i) => React.createRef()),
//     []
//   );

//   const updateCurrentIndex = (val) => {
//     setCurrentIndex(val);
//     currentIndexRef.current = val;
//     setActiveIndex(null); // Reset active index when updating
//     setUserLike(null); //Reset like index like
//   };
//   // Function to reset active index after a delay
//   const resetActiveIndex = () => {
//     setTimeout(() => {
//       setActiveIndex(null);
//       setUserLike(null);
//     }, 1000); // Adjust the delay as per your requirement
//   };

//   // set last direction and decrease current index
//   const swiped = (direction, name, index) => {
//     setLastDirection(direction);
//     console.log(`dir on: ${direction}`);
//     updateCurrentIndex(index - 1);
//     // actionOnSwipe(direction, name)
//   };
//   // function actionOnSwipe(direction, name) {
//   //   if(direction === 'right') {
//   //     setUserLike(userLike => [...userLike, name])
//   //   }
//   // }

//   //card left the screen
//   const outOfFrame = (name, idx) => {
//     console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
//     // handle the case in which go back is pressed before card goes outOfFrame
//     currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
//     // TODO: when quickly swipe and restore multiple times the same card,
//     // it happens multiple outOfFrame events are queued and the card disappear
//     // during latest swipes. Only the last outOfFrame event should be considered valid
//   };

//   const swipe = async (dir) => {
//     if (canSwipe && currentIndex < data.length) {
//       await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
//     }
//   };

//   // increase current index and show card
//   const goBack = async () => {
//     if (!canGoBack) return;
//     const newIndex = currentIndex + 1;
//     updateCurrentIndex(newIndex);
//     await childRefs[newIndex].current.restoreCard();
//   };

//   return (
//     <div className="flash-cards">
//       <div className="flash-cards__cards">
//         {isLoading ? <Loading /> :
//           data.map((character, index) => (
//             <TinderCard
//               ref={childRefs[index]}
//               className={`flash-cards__card ${
//                 activeIndex === index ? "active" : ""
//               } ${userLike === index ? "like" : ""}`}
//               key={character.id}
//               swipeRequirementType="position"
//               onSwipe={(dir) => {
//                 swiped(dir, character.id, index);
//                 setActiveIndex(index); // Update active index on swipe
//                 resetActiveIndex(); // Reset active index after swipe

//                 if (dir === "right") {
//                   setUserLike(index);
//                 }
//                 if (dir === "left") {
//                   console.log("swipe was left");
//                 }
//               }}
//               onCardLeftScreen={() => outOfFrame(character.name, index)}
//             >
//               <CardId img={character.img} word={character.word} />
//             </TinderCard>
//           ))
//         } 
//       </div>
//       <div className="flash-cards__buttons">
//         <button
//           className="flash-cards__buttons-left"
//           style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
//           onClick={() => swipe("left")}
//         >
//           Swipe left!
//         </button>
//         <button
//           className={`flash-cards__buttons-undo ${!canGoBack ? " hidden" : ""}`}
//           style={{ backgroundColor: !canGoBack && "#c3c4d3" }}
//           onClick={() => goBack()}
//         >
//           <UndoSVG />
//         </button>
//         <button
//           className="flash-cards__buttons-right"
//           style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
//           onClick={() => swipe("right")}
//         >
//           Swipe right!
//         </button>
//       </div>
//       {/* {lastDirection && (
//         <h2 key={lastDirection} className="infoText">
//           Last swipe is: {lastDirection}
//         </h2>
//       )} */}
//     </div>
//   );
// }

export default FlashCards;

{
  /* <div className="flash-cards__card"
    style ={{backgroundImage: `url(${character.imgURL})`}}>
    <h3>{character.name}</h3>
</div> */
}

// {lastDirection ? (
//   <h2 key={lastDirection} className='infoText'>
//     Last swipe is: {lastDirection}
//   </h2>
// ) : (
//   <h2 className='infoText'>
//     Swipe a card or press a button to get Restore Card button visible!
//   </h2>
// )}

// import {useState,useEffect} from 'react';
// import TinderCard from 'react-tinder-card'
// import './index.scss'
// const data = [
//   {
//     name : "Tiger",
//     category : "Tiger",
//     imgURL : 'https://images.unsplash.com/photo-1477764250597-dffe9f601ae8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80'
//   },
//   {
//     name : "Leopard",
//     category : "Leopard",
//     imgURL : 'https://images.unsplash.com/photo-1456926631375-92c8ce872def?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
//   },
//   {
//     name : "Bird",
//     category : "Bird",
//     imgURL : 'https://images.unsplash.com/photo-1444464666168-49d633b86797?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=749&q=80'
//   },
//   {
//     name : "Bear",
//     category : "Bear",
//     imgURL : 'https://images.unsplash.com/photo-1525382455947-f319bc05fb35?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=514&q=80'
//   },
//   {
//     name : "Wolf",
//     category : "Wolf",
//     imgURL : 'https://images.unsplash.com/photo-1474511320723-9a56873867b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
//   },
// ];

// function FlashCards() {

//   const [animals, setAnimals] = useState([])
//   const [userLike, setUserLike] = useState([])
//   const [userDisLike, setUserDisLike] = useState([])

//   useEffect(() => {
//     setAnimals(data)
//   },[])

//   // const onSwipe = (direction) => {
//   //   console.log('You swiped: ' + direction)
//   // }

//   function swiped(direction, category) {
//     switch(direction) {
//       case 'right':
//         return (
//           setUserLike(userLike => [...userLike, category])
//         )
//       case 'left':
//         return (
//           setUserDisLike(userDisLike => [...userDisLike, category])
//         )
//       case 'up':
//         return (
//           console.log('switch up swipe')
//         )
//       case 'down':
//         return (
//           console.log('switch down swipe')
//         )
//     }
//   }

//   // out of the screen!
//   function leftScreen(name){
//     console.log(`card is out of the screen`)
//   }

//   return (
//     <div className="flash-cards">
//         {animals.map((animal) => (
//             <TinderCard
//             key ={animal.name}
//             className="swipe"
//             swipeRequirementType = {'position'}
//             // swipeThreshold = {300}
//             preventSwipe = {['up','down']}
//             onSwipe = {(direction) => swiped(direction,animal.category)}
//             onCardLeftScreen = {() => leftScreen(animal)}
//           >
//               <div className="card"
//                 style ={{backgroundImage: `url(${animal.imgURL})`}}>
//                 <h3>{animal.name}</h3>
//               </div>
//           </TinderCard>
//         ))}
//     </div>

//   );
// }

// export default FlashCards;
